import { MicroSentryClient } from '@micro-sentry/core';
function isMatchingPattern(value, pattern) {
  if (Object.prototype.toString.call(pattern) === '[object RegExp]') {
    return pattern.test(value);
  }
  if (typeof pattern === 'string') {
    return value.indexOf(pattern) !== -1;
  }
  return false;
}
function getWindow() {
  return window;
}
class BrowserMicroSentryClient extends MicroSentryClient {
  constructor(options, window = getWindow()) {
    super(options);
    this.options = options;
    this.window = window;
    this.destroyed = false;
    this._state = {};
    const {
      plugins = [],
      beforeSend = req => req,
      beforeBreadcrumb = breadcrumb => breadcrumb,
      blacklistUrls = [],
      ignoreErrors = [],
      release = undefined
    } = this.options || {};
    this.plugins = plugins.map(Plugin => new Plugin(this));
    this.beforeSend = beforeSend;
    this.beforeBreadcrumb = beforeBreadcrumb;
    this.blacklistUrls = blacklistUrls;
    this.ignoreErrors = ignoreErrors;
    this.release = release;
  }
  get state() {
    return this._state;
  }
  clearState() {
    this._state = {};
  }
  setTags(tags) {
    this.setKeyState('tags', {
      ...tags
    });
    return this;
  }
  setTag(key, value) {
    this.extendState({
      tags: {
        [key]: value
      }
    });
    return this;
  }
  setExtra(key, value) {
    this.extendState({
      extra: {
        [key]: value
      }
    });
    return this;
  }
  setExtras(extras) {
    this.setKeyState('extra', {
      ...extras
    });
    return this;
  }
  setUser(user) {
    this.setKeyState('user', user ? {
      ...user
    } : undefined);
    return this;
  }
  clone() {
    const client = new BrowserMicroSentryClient({
      ...this.options,
      plugins: []
    });
    client.extendState(this.state);
    return client;
  }
  withScope(fn) {
    const clone = this.clone();
    fn(clone);
    clone.destroy();
    this.setBreadcrumbs(undefined);
  }
  addBreadcrumb(breadcrumb) {
    const result = this.beforeBreadcrumb(breadcrumb);
    if (!result) {
      return;
    }
    this.extendState({
      breadcrumbs: [{
        timestamp: Date.now() / 1000,
        ...result
      }]
    });
  }
  setBreadcrumbs(breadcrumbs) {
    this.setKeyState('breadcrumbs', breadcrumbs);
  }
  captureMessage(message, level) {
    this.send({
      ...this.getRequestBlank(),
      message,
      level
    });
  }
  destroy() {
    this.destroyed = true;
    this.plugins.forEach(plugin => {
      if (plugin.destroy) {
        plugin.destroy();
      }
    });
  }
  isIgnoredError(event) {
    if (!this.ignoreErrors.length) {
      return false;
    }
    return this.getPossibleEventMessages(event).some(message => this.ignoreErrors.some(pattern => isMatchingPattern(message, pattern)));
  }
  extractQueryString(originalUrl) {
    let url = originalUrl;
    if (!url) {
      return undefined;
    }
    if (url.startsWith('/')) {
      url = `http://prefix${url}`;
    }
    try {
      const queryString = new URL(url).search.slice(1);
      return queryString.length ? queryString : undefined;
    } catch (e) {
      return undefined;
    }
  }
  getRequestBlank() {
    const url = this.window.location.toString();
    return {
      request: {
        url,
        query_string: this.extractQueryString(url),
        headers: {
          'User-Agent': this.window.navigator.userAgent
        }
      },
      ...super.getRequestBlank(),
      sdk: {
        name: 'micro-sentry.javascript.browser',
        version: '0.0.0'
      },
      ...this.state
    };
  }
  send(request) {
    if (this.destroyed || this.isDeniedUrl(request) || this.isIgnoredError(request)) {
      return;
    }
    const beforeSendResult = this.beforeSend({
      release: this.release,
      ...request
    });
    if (!beforeSendResult) {
      return;
    }
    super.send(beforeSendResult);
    this.setBreadcrumbs(undefined);
  }
  getPossibleEventMessages(event) {
    if (event.message) {
      return [event.message];
    }
    if (event.exception) {
      try {
        const {
          type = '',
          value = ''
        } = event.exception.values && event.exception.values[0] || {};
        return [`${value}`, `${type}: ${value}`];
      } catch (e) {
        return [];
      }
    }
    return [];
  }
  isDeniedUrl(event) {
    if (!this.blacklistUrls.length) {
      return false;
    }
    const url = this.getEventFilterUrl(event);
    return !url ? false : this.blacklistUrls.some(pattern => isMatchingPattern(url, pattern));
  }
  getEventFilterUrl(event) {
    try {
      if (event.exception) {
        const frames = event.exception.values && event.exception.values[0].stacktrace && event.exception.values[0].stacktrace.frames;
        return frames && frames[frames.length - 1].filename || null;
      }
      return null;
    } catch (e) {
      return null;
    }
  }
  extendState(newState) {
    this._state = Object.keys(newState).reduce((acc, key) => {
      const stateValue = this._state[key];
      const stateArray = Array.isArray(stateValue) ? stateValue : null;
      const newStateValue = newState[key];
      const newStateArray = Array.isArray(newStateValue) ? newStateValue : null;
      return {
        ...acc,
        [key]: stateArray || newStateArray ? [...(stateArray || []), ...(newStateArray || [])] : {
          ...(typeof stateValue !== 'string' ? stateValue : {}),
          ...(typeof newStateValue !== 'string' ? newStateValue : {})
        }
      };
    }, this._state);
  }
  setKeyState(key, value) {
    this._state[key] = value;
  }
}

/**
 * Generated bundle index. Do not edit.
 */

export { BrowserMicroSentryClient };
