<div class="bg-zinc-100 min-h-screen px-4 py-8 md:px-6 lg:px-8">
  <div
    style="
      background: radial-gradient(50% 109137.91% at 50% 50%, rgba(233, 30, 99, 0.1) 0%, rgba(254, 244, 247, 0) 100%);
    "
    class="text-center"
  >
    <span class="text-pink-500 font-bold text-3xl inline-block px-3 py-3">404</span>
  </div>
  <div class="mt-6 mb-5 font-bold text-4xl text-zinc-900 text-center">Page Not Found</div>
  <p class="text-zinc-700 text-3xl mt-0 text-center">Sorry, we couldn't find the page.</p>
  <div class="text-center mt-4">
    <a routerLink="/" class="py-2 px-4 text-2xl rounded border hover:bg-zinc-100 hover:shadow cursor-pointer mr-4"><i class="icon-arrow-uturn-left  text-blue-500 mr-2"></i>Go back</a>
    <a routerLink="/" class="py-2 px-4 text-2xl rounded border hover:bg-zinc-100 hover:shadow cursor-pointer"><i class="icon-home  text-blue-500 mr-2"></i>Dashboard</a>
  </div>
</div>
