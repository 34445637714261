import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class CommonInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.body instanceof FormData || req.body instanceof Blob || req.body instanceof File) {
      return next.handle(req);
    } else {
      const clonedReq = req.clone({
        headers: req.headers.set('Content-Type', 'application/json'),
      });
      return next.handle(clonedReq);
    }
  }
}
