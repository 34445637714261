import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LightboxComponent } from '@phothor/shared/lightbox/components/lightbox/lightbox.component';
import { LightgalleryModule } from 'lightgallery/angular';

@NgModule({
  declarations: [LightboxComponent],
  exports: [LightboxComponent],
  imports: [CommonModule, LightgalleryModule],
})
export class LightboxModule {}
