import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError } from 'rxjs';
import { AuthState } from '@phothor/auth/state/auth.state';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { NotificationService } from '@phothor/shared/services/notification.service';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {
  private authHeaderName = 'X-AUTH-TOKEN';
  private refreshing = false;
  private refreshToken$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>('');
  private skipUrls = ['/api/profile/verify-email/resend', '/api/profile/verify-email'];

  constructor(
    private authState: AuthState,
    private notificationService: NotificationService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: true,
    });

    if (!!this.skipUrls.find((url) => request.url.endsWith(url))) {
      return next.handle(request);
    }

    if (this.authState.getToken()) {
      request = this.addToken(request, this.authState.getToken());
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (request.url.endsWith('/api/login')) {
          return throwError(error);
        }
        // handle the 401 response
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        } else if (error instanceof HttpErrorResponse && error.status === 403) {
          return this.handle403Error(request, next);
        } else {
          return throwError(error);
        }
      }),
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.refreshing) {
      this.refreshing = true;
      this.refreshToken$.next(null);
      return this.authState.refreshAuthToken().pipe(
        switchMap((result) => {
          this.refreshing = false;
          return next.handle(this.addToken(request, result.token));
        }),
      );
    } else {
      if (request.url.endsWith('refresh-token')) {
        this.notificationService.showMessage({
          summary: 'Error',
          detail: 'Your session has expired, please login',
          color: 'rose',
          life: 5,
        });
        this.authState.clear();
        window.location.href = environment.host + '/account/login';
      }
      return this.refreshToken$.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => {
          return next.handle(this.addToken(request, token));
        }),
      );
    }
  }

  private handle403Error(request: HttpRequest<any>, next: HttpHandler) {
    this.router.navigateByUrl('/manage/checkout');
    return next.handle(request);
  }

  private addToken(request: HttpRequest<any>, token: string | null): HttpRequest<any> {
    if (token != null) {
      return request.clone({
        headers: request.headers.set(this.authHeaderName, token),
      });
    }
    return request;
  }
}
