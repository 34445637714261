import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { CommonInterceptor } from "./common.interceptor";
import { AuthHeaderInterceptor } from "@phothor/http-interceptors/auth-header.interceptor";
// import { AuthHeaderInterceptor } from './auth-header.interceptor';
// import { ErrorInterceptor } from './error.interceptor';

export const httpInterceptorProviders = [
  // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CommonInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthHeaderInterceptor, multi: true },
];
