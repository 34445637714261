import { Component } from '@angular/core';

@Component({
  selector: 'app-error-page',
  templateUrl: 'error-page.component.html',
})
export class ErrorPageComponent {
  constructor() {}

}
