import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { map, Observable, startWith, Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UIHelperService {
  public resizeSource: Subject<number> = new Subject<number>();
  public isMobile$: Observable<boolean> = this.resizeSource.pipe(
    startWith(window.innerWidth),
    map((innerWidth: number) => innerWidth < 768),
  );

  constructor(
    private router: Router,
    private title: Title,
    @Inject(DOCUMENT) private document: Document,
  ) {
    router.events.subscribe((val) => {
      title.setTitle('SendPhoto');
    });
    title.setTitle('SendPhoto');
  }

  changeTheme() {
    const themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;

    let theme;
    if (themeLink) {
      let curr = themeLink.href.split('/').pop()?.split('.')[0];
      if (!curr) return;
      if (curr.includes('dark')) {
        theme = curr.replace('dark', 'light');
      } else {
        theme = curr.replace('light', 'dark');
      }
      themeLink.href = window.location.origin + '/' + theme + '.css';
    }
  }
}
