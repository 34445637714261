import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@phothor/auth/auth.guard';
import { ErrorPageComponent } from '@phothor/error-page-component';

const routes: Routes = [
  {
    path: 'manage',
    loadChildren: () => import('@phothor/manage/manage.module').then((m) => m.ManageModule),
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Home' },
  },
  {
    path: 'account',
    loadChildren: () => import('@phothor/auth/auth.module').then((m) => m.AuthModule),
    data: { breadcrumb: 'Account' },
  },
  {
    path: '',
    redirectTo: '/manage/galleries',
    pathMatch: 'prefix',
  },
  {
    path: '**',
    component: ErrorPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
