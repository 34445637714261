import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import posthog from 'posthog-js';
import { environment } from './environments/environment';

if (environment.production) {
  posthog.init(environment.posthogKey, {
    api_host: environment.posthogApiHost,
    ui_host: environment.posthogUiHost,
    person_profiles: 'always',
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
