import { Component } from '@angular/core';
import { LightboxEvent, LightboxImage } from '@phothor/shared/lightbox/lightboxImage';
import { LightboxService } from '@phothor/shared/lightbox/lightbox.service';

import { LightGallery } from 'lightgallery/lightgallery';
import lgZoom from 'lightgallery/plugins/zoom';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import {AfterCloseDetail, InitDetail} from 'lightgallery/lg-events';

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
})
export class LightboxComponent {
  images: LightboxImage[] = [];
  imageIndex: number = 0;

  settings = {
    counter: false,
    plugins: [lgZoom, lgThumbnail, lgFullscreen],
    licenseKey: 'B8931C13-A296-4351-8432-D368648DC6DC',
  };
  lightGallery!: LightGallery;

  constructor(private lightboxService: LightboxService) {
    this.lightboxService.lightboxEvent$.subscribe((event: LightboxEvent) => {
      document.getElementsByTagName('body').item(0)!.style.overflow = 'hidden';
      this.images = event.photos;
      this.imageIndex = event.photos.findIndex((photo) => photo.id === event.selectedId);
      this.lightGallery.refresh(this.images);
      this.lightGallery.openGallery(this.imageIndex);
    });
  }

  onInitLightGallery = (detail: InitDetail): void => {
    this.lightGallery = detail.instance;
  };

  closeLightbox(detail: AfterCloseDetail) {
    document.getElementsByTagName('body').item(0)!.style.overflow = '';
  }
}
