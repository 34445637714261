import { Injectable } from '@angular/core';

import { AuthState } from '@phothor/auth/state/auth.state';
import { Observable } from 'rxjs';
import { AuthService } from '@phothor/auth/auth.service';

@Injectable()
export class AuthGuard {
  constructor(private authState: AuthState, private authService: AuthService) {}

  canActivate(): Observable<boolean> {
    return this.authService.checkAuth();
  }

  isLoggedIn() {
    return this.authState.isAuthenticated();
  }
}
