import { Component, HostListener, OnInit } from '@angular/core';
import { UIHelperService } from './shared/services/ui-helper.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import {AuthState} from "@phothor/auth/state/auth.state";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'client-gallery-ui';

  constructor(
    private UIHelper: UIHelperService,
    private router: Router,
    private authState: AuthState,
  ) {
    this.UIHelper.resizeSource.next(window.innerWidth);
  }

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(event: number) {
    this.UIHelper.resizeSource.next(event);
  }

  ngOnInit(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.hideLoader();
    });
    this.authState.startTokenUpdater();
  }

  hideLoader() {
    // Fetch the loader
    const loader = document.getElementById('loader-wrapper');

    // Set loading screen to no display after app is initialized
    if (loader) {
      setTimeout(() => {
        loader.style.display = 'none';
      }, 1); // This time is arbitrary, you could also remove it completely
    }
  }
}
