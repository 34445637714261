import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { httpInterceptorProviders } from './http-interceptors';
import { AuthState } from '@phothor/auth/state/auth.state';
import { AuthGuard } from '@phothor/auth/auth.guard';
import { AuthService } from '@phothor/auth/auth.service';
import { ErrorPageComponent } from '@phothor/error-page-component';
import { HttpClientModule } from '@angular/common/http';
import { MessagesModule } from '@phothor/shared/messages/messages.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LightboxModule } from '@phothor/shared/lightbox/lightbox.module';
import { NgxStripeModule } from 'ngx-stripe';
import { CheckCircle, EyeOff, LucideAngularModule, XCircle } from 'lucide-angular';
import { popperVariation, provideTippyConfig, tooltipVariation } from '@ngneat/helipopper';
import { NgxTurnstileModule } from 'ngx-turnstile';
import { MicroSentryModule } from '@micro-sentry/angular';
import { environment } from '../environments/environment';
import { provideMatomo, withRouter } from 'ngx-matomo-client';

@NgModule({
  declarations: [AppComponent, ErrorPageComponent],
  imports: [
    BrowserModule,
    NgxStripeModule.forRoot(environment.stripeKey),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MessagesModule,
    LightboxModule,
    LucideAngularModule.pick({ CheckCircle, XCircle, EyeOff }),
    NgxTurnstileModule,
    MicroSentryModule.forRoot({
      dsn: environment.sentryDSN,
      release: environment.version,
      environment: environment.production ? 'production' : 'development',
    }),
  ],
  providers: [
    httpInterceptorProviders,
    AuthState,
    AuthGuard,
    AuthService,
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
      },
    }),
    ...(environment.production
      ? [provideMatomo({ scriptUrl: 'https://analytics.sendphoto.io/js/container_dJ6WwJNK.js' }, withRouter())]
      : []),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
